<template>
    <div style="height:70vh;display:flex;align-items: center;">
        <div style="font-size: 27px;text-align: center;line-height: 36px;font-style: oblique;">
                <div>{{board}}</div>
        </div>
    </div>
</template>

<script>
    import {getBoard} from '@/api/eformApi'
    export default {
        name: "Board",
        data() {
            return {
                board:'',
                brandName:''
            };
        },
    created() {
        this.checkBrandName()
           this.getBoard()
        },
        mounted(){
        },
        methods: {
            getBoard() {
                getBoard({
                    brandName: this.brandName,
                }).then((res)=>{
                    if(res.success){
                        console.error(res.data)
                        this.board = res.data
                    }else{
                        console.log(res.success)
                    }
                })
            },
            checkBrandName(){
                let brandList = ['esteelauder','bobbibrown','lamer','labseries','origins']
                this.brandName = brandList.includes(location.hostname.split('.')[1])?location.hostname.split('.')[1]:'origins'
            },
        }
    }
</script>

